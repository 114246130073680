import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Illustration from '../components/Illustration';
import './ShowcaseBlock.css';
import './ShowcaseBlock-ss.css';

import API_BASE_URL from '../config';

// Importations des images des catégories
import animalsIcon from '../assets/categories/Animals.png';
import automobileIcon from '../assets/categories/Automobile.png';
import cateringIcon from '../assets/categories/Catering.png';
import constructionIcon from '../assets/categories/Construction.png';
import educationIcon from '../assets/categories/Education.png';
import farmingIcon from '../assets/categories/Farming.png';
import natureIcon from '../assets/categories/Nature.png';
import performanceIcon from '../assets/categories/Performance.png';
import sportIcon from '../assets/categories/Sport.png';
import transportationIcon from '../assets/categories/Transportation.png';

// Importations des applications
import ChessMiniApp from '../components/ChessMiniApp/ChessMiniApp';
import DJMiniApp from '../components/DJMiniApp/DJMiniApp';

// Importations des drapeaux des langues
import enFlag from '../assets/flags/EN.png';
import spFlag from '../assets/flags/SP.png';
import frFlag from '../assets/flags/FR.png';
import deFlag from '../assets/flags/DE.png';
import arFlag from '../assets/flags/AR.png';
import elFlag from '../assets/flags/EL.png';
import heFlag from '../assets/flags/HE.png';
import hiFlag from '../assets/flags/HI.png';
import itFlag from '../assets/flags/IT.png';
import jpFlag from '../assets/flags/JP.png';
import koFlag from '../assets/flags/KO.png';
import nlFlag from '../assets/flags/NL.png';
import ptFlag from '../assets/flags/PT.png';
import ruFlag from '../assets/flags/RU.png';
import tuFlag from '../assets/flags/TU.png';
import zhFlag from '../assets/flags/ZH.png';

// Importation des icônes
import printIcon from '../assets/icons/Print.png';

// Importation du GIF de chargement
import loadingGif from '../assets/loading.gif';

const renderApplication = (application) => {
  if (!application) return null;

  switch (application.type) {
    case 'chess':
      return <ChessMiniApp config={application.config} />;
    case 'dj':
      if (!application.config || !application.config.track1 || !application.config.track2) {
        console.error('Configuration DJ invalide:', application);
        return <div>Configuration DJ manquante ou invalide</div>;
      }
      return <DJMiniApp 
        track1Url={`${API_BASE_URL}${application.config.track1}`}
        track2Url={`${API_BASE_URL}${application.config.track2}`}
      />;
    default:
      console.warn(`Type d'application non reconnu: ${application.type}`);
      return null;
  }
};

function ShowcaseBlock() {
  const { id } = useParams();
  const [block, setBlock] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeContent, setActiveContent] = useState('description');
  const [selectedCategory, setSelectedCategory] = useState('');

  // États pour les médias
  const [isDescriptionPlaying, setIsDescriptionPlaying] = useState(false);
  const [isTextAudioPlaying, setIsTextAudioPlaying] = useState(false);
  const [isLabelAudioPlaying, setIsLabelAudioPlaying] = useState(false);
  const [isTitleAudioPlaying, setIsTitleAudioPlaying] = useState(false);
  const [isZoneAudioPlaying, setIsZoneAudioPlaying] = useState(false);

  // Références pour les médias
  const descriptionRef = useRef(null);
  const textAudioRef = useRef(null);
  const labelAudioRef = useRef(null);
  const titleAudioRef = useRef(null);
  const zoneAudioRef = useRef(null);
  const zoneVideoRef = useRef(null);

  const [currentZone, setCurrentZone] = useState(null);
  const [selectedZoneContent, setSelectedZoneContent] = useState({});

  // Maps des icônes et drapeaux
  const categoryIconsMap = {
    Animals: animalsIcon,
    Automobile: automobileIcon,
    Catering: cateringIcon,
    Construction: constructionIcon,
    Education: educationIcon,
    Farming: farmingIcon,
    Nature: natureIcon,
    Performance: performanceIcon,
    Sport: sportIcon,
    Transportation: transportationIcon
  };

  const languageFlagsMap = {
    EN: enFlag,
    SP: spFlag,
    FR: frFlag,
    DE: deFlag,
    AR: arFlag,
    EL: elFlag,
    HE: heFlag,
    HI: hiFlag,
    IT: itFlag,
    JP: jpFlag,
    KO: koFlag,
    NL: nlFlag,
    PT: ptFlag,
    RU: ruFlag,
    TU: tuFlag,
    ZH: zhFlag,
  };

  // Fonction pour arrêter tous les médias audio
  const stopAllAudioMedia = () => {
    console.log('Stopping all audio media');
    const audioElements = [
      descriptionRef.current,
      textAudioRef.current,
      labelAudioRef.current,
      titleAudioRef.current,
      zoneAudioRef.current
    ];

    audioElements.forEach(media => {
      if (media && !media.paused) {
        console.log('Stopping audio:', media);
        media.pause();
        media.currentTime = 0;
      }
    });

    // Réinitialiser tous les états de lecture audio
    setIsDescriptionPlaying(false);
    setIsTextAudioPlaying(false);
    setIsLabelAudioPlaying(false);
    setIsTitleAudioPlaying(false);
    setIsZoneAudioPlaying(false);
  };

  // Fonction pour gérer le démarrage d'un audio
  const handleAudioStart = (audioRef, setPlayingState) => {
    // Si la vidéo est en cours de lecture, l'arrêter
    if (zoneVideoRef.current && !zoneVideoRef.current.paused) {
      console.log('Stopping video to play audio');
      zoneVideoRef.current.pause();
    }
    
    // Arrêter les autres audios
    stopAllAudioMedia();
    
    // Démarrer l'audio demandé
    audioRef.current.play().catch(console.error);
    setPlayingState(true);
  };

  // Fonction pour gérer la séquence des médias
  const handleMediaSequence = (content) => {
    // Arrêt de tous les audios
    stopAllAudioMedia();
    
    if (content.audio) {
      // Priorité 1: Audio lié à l'image
      console.log('Playing zone audio');
      zoneAudioRef.current.src = content.audio;
      zoneAudioRef.current.play().catch(console.error);
      setIsZoneAudioPlaying(true);
      
      // Configurer l'événement de fin pour passer au média suivant
      zoneAudioRef.current.onended = () => {
        setIsZoneAudioPlaying(false);
        if (content.textAudio) {
          playTextAudio(content);
        }
      };
    } else if (content.textAudio) {
      // Priorité 2: Audio lié au texte
      playTextAudio(content);
    }

    // Préparer la vidéo indépendamment des audios
    if (content.video) {
      prepareVideo(content);
    }
  };

  // Fonction pour jouer l'audio du texte
  const playTextAudio = (content) => {
    console.log('Playing text audio');
    textAudioRef.current.src = content.textAudio;
    textAudioRef.current.play().catch(console.error);
    setIsTextAudioPlaying(true);
    
    // L'audio du texte se termine simplement
    textAudioRef.current.onended = () => {
      setIsTextAudioPlaying(false);
    };
  };

  // Fonction pour préparer la vidéo
  const prepareVideo = (content) => {
    console.log('Preparing video');
    if (zoneVideoRef.current) {
      zoneVideoRef.current.src = content.video;
      zoneVideoRef.current.style.display = 'block';
      zoneVideoRef.current.controls = true;
    }
  };

  // Fonction pour gérer le clic sur une zone
  const handleZoneClick = (area) => {
    let content = area.content;
    let parsedContent = {};

    if (typeof content === 'string') {
      try {
        parsedContent = JSON.parse(content);
      } catch (e) {
        parsedContent = { text: content };
      }
    } else if (typeof content === 'object') {
      parsedContent = content;
    } else {
      parsedContent = { text: String(content) };
    }

    if (content && content.application) {
      parsedContent.application = {
        type: content.application.type,
        config: {
          track1: content.application.track1 || content.application.config?.track1,
          track2: content.application.track2 || content.application.config?.track2
        }
      };
    }

    // Ajouter le label de la zone si non présent
    parsedContent.label = parsedContent.label || area.label || '';

    // Préfixer les URLs des fichiers
    ['image', 'audio', 'video', 'textAudio', 'labelAudio'].forEach(mediaType => {
      if (parsedContent[mediaType] && !parsedContent[mediaType].startsWith('http')) {
        parsedContent[mediaType] = `${API_BASE_URL}${parsedContent[mediaType]}`;
      }
    });

    setCurrentZone(area);
    setSelectedZoneContent(parsedContent);
    setActiveContent('zoneContent');

    // Démarrer la séquence des médias
    handleMediaSequence(parsedContent);
  };

  // Gestionnaires pour les différents médias
  const handleTextAudioToggle = () => {
    if (!isTextAudioPlaying) {
      handleAudioStart(textAudioRef, setIsTextAudioPlaying);
    } else {
      textAudioRef.current.pause();
      setIsTextAudioPlaying(false);
    }
  };

  const handleLabelAudioToggle = () => {
    if (!isLabelAudioPlaying) {
      handleAudioStart(labelAudioRef, setIsLabelAudioPlaying);
    } else {
      labelAudioRef.current.pause();
      setIsLabelAudioPlaying(false);
    }
  };

  const handleTitleAudioToggle = () => {
    if (!isTitleAudioPlaying) {
      handleAudioStart(titleAudioRef, setIsTitleAudioPlaying);
    } else {
      titleAudioRef.current.pause();
      setIsTitleAudioPlaying(false);
    }
  };

  const handleDescriptionAudioToggle = () => {
    if (!isDescriptionPlaying) {
      handleAudioStart(descriptionRef, setIsDescriptionPlaying);
    } else {
      descriptionRef.current.pause();
      setIsDescriptionPlaying(false);
    }
  };

  // Fonction pour trouver la zone en haut à gauche
  const findTopLeftZone = (areas) => {
    if (!areas || areas.length === 0) return null;
    
    return areas.reduce((topLeft, current) => {
      if (!topLeft) return current;
      
      const topLeftDistance = Math.sqrt(topLeft.x ** 2 + topLeft.y ** 2);
      const currentDistance = Math.sqrt(current.x ** 2 + current.y ** 2);
      
      return currentDistance < topLeftDistance ? current : topLeft;
    });
  };

  // Fonction pour gérer l'impression
  const handlePrint = () => {
    if (block?.pdf) {
      const pdfUrl = `${API_BASE_URL}${block.pdf}`;
      window.open(pdfUrl, '_blank');
    }
  };

  // Récupération des données du bloc
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/blocks/${id}`)
      .then((res) => {
        if (res.data) {
          setBlock(res.data);
          
          if (res.data.areas && res.data.areas.length > 0) {
            const topLeftZone = findTopLeftZone(res.data.areas);
            if (topLeftZone) {
              handleZoneClick(topLeftZone);
              setActiveContent('zoneContent');
            }
          } else {
            setActiveContent('description');
          }
        }
      })
      .catch((err) => {
        console.error('Erreur lors de la récupération des données du bloc :', err);
        setErrorMessage('Échec de la récupération des données du bloc.');
      });
  }, [id]);

  // Nettoyage à la destruction du composant
  useEffect(() => {
    return () => {
      stopAllAudioMedia();
    };
  }, []);

  if (!block) {
    return (
      <div className="loading">
        <img src={loadingGif} alt="Chargement" />
      </div>
    );
  }

  return (
    <div className="showcase-block">
      {/* Header Container avec Titre et Icônes */}
      <div className="header-container">
        {/* Titre Cliquable avec TitleAudio */}
        <h1
          className={`block-title ${block.titleAudio ? 'clickable-title' : ''}`}
          onClick={block.titleAudio ? handleTitleAudioToggle : null}
          role={block.titleAudio ? 'button' : undefined}
          tabIndex={block.titleAudio ? 0 : undefined}
          aria-pressed={isTitleAudioPlaying}
          aria-label={block.titleAudio ? "Lire ou mettre en pause l'audio du titre" : undefined}
        >
          {block.title}
          {block.titleAudio && (
            <audio
              ref={titleAudioRef}
              src={`${API_BASE_URL}${block.titleAudio}`}
            />
          )}
        </h1>

        <div className="icons-container">
          {block.language && (
            <button className="icon language-icon" aria-label="Sélectionner la langue">
              <img src={languageFlagsMap[block.language]} alt="Langue" />
            </button>
          )}
          {block.categories &&
            block.categories.map((category, index) => (
              <button
                key={index}
                className="icon category-icon"
                onClick={() => setSelectedCategory(category)}
                aria-label={`Catégorie ${category}`}
              >
                <img src={categoryIconsMap[category]} alt={category} />
              </button>
            ))}
          <button className="icon print-button" onClick={handlePrint} aria-label="Imprimer le PDF">
            <img src={printIcon} alt="Print" />
          </button>
        </div>
      </div>

      {/* Conteneur Principal avec Colonnes */}
      <div className="content-container">
        {/* Colonne de Gauche */}
        <div className="left-column">
          <div className="illustration-container">
            <Illustration
              illustrationUrl={`${API_BASE_URL}${block.illustration}`}
              areas={block.areas || []}
              handleZoneClick={handleZoneClick}
            />
          </div>
        </div>

        {/* Colonne de Droite */}
        <div className="right-column">
          <div className="right-content">
            {/* Contenu de la Zone Sélectionnée */}
            {activeContent === 'zoneContent' && selectedZoneContent && (
              <div className="zone-content">
                {/* Label en haut */}
                {selectedZoneContent.label && (
                  <strong
                    className="zone-label clickable-label"
                    onClick={handleLabelAudioToggle}
                    role="button"
                    tabIndex={0}
                    aria-pressed={isLabelAudioPlaying}
                    aria-label="Lire ou mettre en pause l'audio du label"
                  >
                    {selectedZoneContent.label}
                    {selectedZoneContent.labelAudio && (
                      <audio
                        ref={labelAudioRef}
                        src={selectedZoneContent.labelAudio}
                      />
                    )}
                  </strong>
                )}

                {/* Image si disponible */}
                {selectedZoneContent.image && (
                  <div className="zone-image">
                    <img
                      src={selectedZoneContent.image}
                      alt="Zone"
                      className={selectedZoneContent.audio ? 'clickable-image' : ''}
                      title={selectedZoneContent.audio ? 'Cliquer pour contrôler la lecture du média' : ''}
                    />
                  </div>
                )}

                {/* Audio de la zone (caché) */}
                {selectedZoneContent.audio && (
                  <audio
                    ref={zoneAudioRef}
                    src={selectedZoneContent.audio}
                    style={{ display: 'none' }}
                  />
                )}

                {/* Texte interactif pour contrôler l'audio */}
                {selectedZoneContent.text && (
                  <div className="text-content">
                    <div
                      onClick={selectedZoneContent.textAudio ? handleTextAudioToggle : null}
                      role={selectedZoneContent.textAudio ? 'button' : undefined}
                      tabIndex={selectedZoneContent.textAudio ? 0 : undefined}
                      aria-pressed={isTextAudioPlaying}
                      aria-label={selectedZoneContent.textAudio ? "Lire ou mettre en pause l'audio du texte" : undefined}
                      className={selectedZoneContent.textAudio ? 'clickable-text' : ''}
                    >
                      {selectedZoneContent.text}
                    </div>

                    {selectedZoneContent.textAudio && (
                      <audio
                        ref={textAudioRef}
                        src={selectedZoneContent.textAudio}
                      />
                    )}
                  </div>
                )}

                {/* Vidéo de la zone */}
                {selectedZoneContent.video && (
                  <div className="zone-video-display">
                    <video
                      ref={zoneVideoRef}
                      src={selectedZoneContent.video}
                      controls
                      style={{ width: '100%', borderRadius: '8px' }}
                      onPlay={() => {
                        console.log('Video started - stopping all audio');
                        stopAllAudioMedia(); // Arrête tous les audios quand la vidéo démarre
                      }}
                      onPause={() => {
                        console.log('Video paused');
                      }}
                    />
                  </div>
                )}

                {/* Application de la zone */}
                {selectedZoneContent.application && (
                  <div className="zone-application">
                    {renderApplication(selectedZoneContent.application)}
                  </div>
                )}
              </div>
            )}

            {/* Description du Bloc */}
            {activeContent === 'description' && (
              <div className="description-content">
                <h2>Description</h2>
                <p>{block.description}</p>
                {block.portrait && (
                  <div className="portrait-image">
                    <img src={`${API_BASE_URL}${block.portrait}`} alt="Portrait" />
                  </div>
                )}
                {block.descriptionlue && (
                  <div className="description-audio">
                    <audio
                      ref={descriptionRef}
                      src={`${API_BASE_URL}${block.descriptionlue}`}
                      controls
                      onPlay={() => {
                        stopAllAudioMedia();
                        setIsDescriptionPlaying(true);
                      }}
                      onPause={() => setIsDescriptionPlaying(false)}
                    >
                      Votre navigateur ne supporte pas la lecture audio.
                    </audio>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowcaseBlock;