import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../config';
import './ListBlocks.css';

import animalsIcon from '../assets/categories/Animals.png';
import automobileIcon from '../assets/categories/Automobile.png';
import cateringIcon from '../assets/categories/Catering.png';
import constructionIcon from '../assets/categories/Construction.png';
import educationIcon from '../assets/categories/Education.png';
import farmingIcon from '../assets/categories/Farming.png';
import natureIcon from '../assets/categories/Nature.png';
import performanceIcon from '../assets/categories/Performance.png';
import sportIcon from '../assets/categories/Sport.png';
import transportationIcon from '../assets/categories/Transportation.png';

import enFlag from '../assets/flags/EN.png';
import spFlag from '../assets/flags/SP.png';
import frFlag from '../assets/flags/FR.png';
import deFlag from '../assets/flags/DE.png';
import arFlag from '../assets/flags/AR.png';
import elFlag from '../assets/flags/EL.png';
import heFlag from '../assets/flags/HE.png';
import hiFlag from '../assets/flags/HI.png';
import itFlag from '../assets/flags/IT.png';
import jpFlag from '../assets/flags/JP.png';
import koFlag from '../assets/flags/KO.png';
import nlFlag from '../assets/flags/NL.png';
import ptFlag from '../assets/flags/PT.png';
import ruFlag from '../assets/flags/RU.png';
import tuFlag from '../assets/flags/TU.png';
import zhFlag from '../assets/flags/ZH.png';

const getResponsiveStyles = () => {
  const baseGridColumnWidth = window.innerWidth < 480 ? 100 : 
                             window.innerWidth < 768 ? 110 :
                             window.innerWidth < 1024 ? 120 : 140;

  const baseIconWidth = window.innerWidth < 480 ? 92 : 
                       window.innerWidth < 768 ? 102 :
                       window.innerWidth < 1024 ? 112 : 132;

  const baseIconHeight = Math.floor(baseIconWidth * 0.73);
  
  const baseFontSize = window.innerWidth < 480 ? '10px' : 
                      window.innerWidth < 768 ? '11px' :
                      window.innerWidth < 1024 ? '12px' : '13px';

  const baseFlagSize = window.innerWidth < 480 ? 20 : 
                      window.innerWidth < 768 ? 24 :
                      window.innerWidth < 1024 ? 28 : 32;

  return {
    gridContainer: {
      paddingTop: window.innerWidth < 768 ? '10px' : '30px',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, minmax(${baseGridColumnWidth}px, 1fr))`,
      gap: window.innerWidth < 768 ? '6px' : '20px',
      padding: window.innerWidth < 768 ? '6px' : '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      border: '1px solid #ddd'
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: window.innerWidth < 768 ? '2px' : '10px',
      cursor: 'pointer',
      backgroundColor: 'white',
      borderRadius: '8px',
      transition: 'transform 0.2s, box-shadow 0.2s',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      height: '100%'
    },
    iconWrapper: {
      position: 'relative',
      marginBottom: window.innerWidth < 768 ? '2px' : '8px'
    },
    iconImage: {
      width: `${baseIconWidth}px`,
      height: `${baseIconHeight}px`,
      objectFit: 'cover',
      borderRadius: '4px'
    },
    iconTitle: {
      fontSize: baseFontSize,
      textAlign: 'center',
      width: '100%',
      minHeight: 'calc(1.2em * 2)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      lineHeight: '1.2em',
      marginTop: 'auto',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      padding: window.innerWidth < 768 ? '0 1px' : '0 4px'
    },
    flag: {
      position: 'absolute',
      top: -5,
      right: -5,
      width: `${baseFlagSize}px`,
      height: `${baseFlagSize}px`,
      borderRadius: '50%',
      border: '2px solid white',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      backgroundColor: 'white',
      padding: '1px'
    }
  };
};

const ListBlocks = () => {
  const [blocks, setBlocks] = useState([]);
  const [filteredBlocks, setFilteredBlocks] = useState([]);
  const [activeLanguages, setActiveLanguages] = useState(new Set());
  const [activeCategories, setActiveCategories] = useState(new Set());
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [styles, setStyles] = useState(getResponsiveStyles());

  const categoryIcons = {
    Animals: animalsIcon,
    Automobile: automobileIcon,
    Catering: cateringIcon,
    Construction: constructionIcon,
    Education: educationIcon,
    Farming: farmingIcon,
    Nature: natureIcon,
    Performance: performanceIcon,
    Sport: sportIcon,
    Transportation: transportationIcon
  };

  const languageFlags = {
    EN: enFlag,
    SP: spFlag,
    FR: frFlag,
    DE: deFlag,
    AR: arFlag,
    EL: elFlag,
    HI: hiFlag,
    HE: heFlag,
    IT: itFlag,
    JP: jpFlag,
    KO: koFlag,
    NL: nlFlag,
    PT: ptFlag,
    RU: ruFlag,
    TU: tuFlag,
    ZH: zhFlag
  };

  useEffect(() => {
    const handleResize = () => {
      setStyles(getResponsiveStyles());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        console.log('Blocks trouvés:', blocks.length);
        console.log('Contenu des blocks:', blocks);
        
        const config = {
          headers: {
            'Authorization': token ? `Bearer ${token}` : ''
          }
        };
        console.log('Config requête:', config);

        const response = await axios.get(`${API_BASE_URL}/api/blocks`, config);
        console.log('Réponse blocks:', response.data);
    
        if (!response.data) {
          throw new Error('No data received');
        }
        
        setBlocks(response.data);
        setFilteredBlocks(response.data);

        
        const languages = new Set();
        const categories = new Set();
        
        response.data.forEach(block => {
          if (block.language) {
            languages.add(block.language);
          }
          if (Array.isArray(block.categories)) {
            block.categories.forEach(cat => categories.add(cat));
          }
        });
        
        setActiveLanguages(languages);
        setActiveCategories(categories);
        setFilteredBlocks(response.data);
        setError(null);
      } catch (error) {
        console.error('Error détaillée:', {
          error: error,
          response: error.response?.data,
          status: error.response?.status
        });       
        setError('Erreur lors du chargement des blocks');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlocks();
  }, []);

  useEffect(() => {
    let filtered = [...blocks];
    
    if (selectedLanguage) {
      filtered = filtered.filter(block => block.language === selectedLanguage);
    }
    
    if (selectedCategory) {
      filtered = filtered.filter(block => 
        block.categories?.includes(selectedCategory)
      );
    }
    
    setFilteredBlocks(filtered);
  }, [blocks, selectedLanguage, selectedCategory]);

  const toggleLanguage = (language) => {
    setSelectedLanguage(prev => prev === language ? null : language);
  };

  const toggleCategory = (category) => {
    setSelectedCategory(prev => prev === category ? null : category);
  };

  if (error) return <div className="error-message">{error}</div>;
  if (isLoading) return <div className="loading-message">Chargement des blocks...</div>;

  return (
    <div className="list-blocks">
      <div className="filters-section" style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '15px' }}>
        <div className="filter-row language-filters">
          {Array.from(activeLanguages).map(language => (
            <img
              key={language}
              src={languageFlags[language]}
              alt={`Drapeau ${language}`}
              title={language}
              className={`filter-icon ${!selectedLanguage || selectedLanguage === language ? 'active' : 'inactive'}`}
              onClick={() => toggleLanguage(language)}
            />
          ))}
        </div>

        <div className="filter-row category-filters">
          {Array.from(activeCategories).map(category => (
            <img
              key={category}
              src={categoryIcons[category]}
              alt={`Icône ${category}`}
              title={category}
              className={`filter-icon ${!selectedCategory || selectedCategory === category ? 'active' : 'inactive'}`}
              onClick={() => toggleCategory(category)}
            />
          ))}
        </div>
      </div>


      <div style={styles.gridContainer}>  {/* Nouveau conteneur */}

      <div style={styles.grid}>
        {filteredBlocks.map((block) => (
          <Link 
            key={block._id} 
            to={`/showcase/${block._id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <div 
              style={styles.iconContainer}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'translateY(-2px)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
              }}
            >
              <div style={styles.iconWrapper}>
                {block.icon ? (
                  <img
                    src={`${API_BASE_URL}${block.icon}`}
                    alt={`Icône de ${block.title}`}
                    style={styles.iconImage}
                  />
                ) : (
                  <div 
                    style={{
                      ...styles.iconImage,
                      backgroundColor: '#f0f0f0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <span>No Icon</span>
                  </div>
                )}
                {block.language && (
                  <img
                    src={languageFlags[block.language]}
                    alt={`Langue ${block.language}`}
                    style={styles.flag}
                    title={block.language}
                  />
                )}
              </div>
              <div style={styles.iconTitle} title={block.title}>
                {block.title}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
    </div>

  );
};

export default ListBlocks;